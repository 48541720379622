.banner {
  background-image: url("./background.png");
  background-size: cover;
  background-position: center;
  padding: 5% 5% 8% 5%;
  color: #fff;
  width: 100%; /* Adjust based on your specific layout needs */
  height: 100%; /* Adjust based on your specific layout needs */
}

.main {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto; /* Center align the content */
}

.left-container {
  width: 100%;
  max-width: 100%; /* Adjust max-width as needed */
}

.bannerLogo {
  width: 20%; /* Adjust width as needed */
  max-width: 200px; /* Adjust max-width as needed */
}

.textContainer {
  margin-top: 5em;
  width: 100%;
}

.bannerTitle {
  font-size: 7.5em; /* Responsive font size */
}

.bannerSubtitle {
  /* margin-top: 2em; */
  font-size: 3.5vw; /* Responsive font size */
}

.bannerDescription {
  color: #fff;
  font-family: Inter;
  font-size: 3.5em; /* Adjust based on your specific layout needs */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.bannerButton {
  border-radius: 100px;
  background: #fff;
  color: #2d2d2d;
  text-align: center;
  font-family: Inter;
  /* font-size: 3rem;  */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 3.7em;
  padding: var(--Gap-and-Padding-Positive-GapPadding-8, 8px) 80px;
  gap: var(--Gap-and-Padding-Positive-GapPadding-8, 8px);
}

.rightContainer {
  /* display: none;  */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 100%; /* Adjust max-width as needed */
  height: auto;
  margin-top: 10em;
}

@media (min-width: 768px) {
  .rightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 100%; /* Adjust max-width as needed */
    height: auto;
  }

  .bannerLogo {
    width: 30%; /* Adjust width as needed */
    max-width: 250px; /* Adjust max-width as needed */
  }

  .bannerTitle {
    font-size: 7.5em;
    /* font-size: 120px; */
  }

  .bannerSubtitle {
    font-size: 2.5vw;
    margin-top: 1em;
    color: #fff;
    font-family: Inter;
    /* font-size: 56px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .bannerDescription {
    margin-top: 1em;
    color: #fff;
    font-family: Inter;
    font-size: 2.5em; /* Adjust based on your specific layout needs */
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  .bannerButton {
    font-size: 1.5vw; /* Responsive font size */
  }
}
