* {
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.containerOne {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  padding: 0 6.25rem; /* 100px padding left and right (100px / 16 = 6.25rem) */
  /* max-width: 100%; */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  justify-content: center;
  justify-content: center;
  align-items: center;
}
.containerTwo {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  padding: 0 6.25rem; /* 100px padding left and right (100px / 16 = 6.25rem) */
  /* max-width: 100%; */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  justify-content: center;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Ensure this content is added to the existing Portfolio.module.css */

.blogSection {
  margin-bottom: 2rem; /* Space between sections */
}

.horizontalLine {
  margin-top: 2rem;
  border: 1px solid #181717;
  /* background: #181717; */
}

.sectionTitle {
  text-align: center;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}
.work {
  color: #2d2d2d;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 1.4856rem; /* 23.77px / 16 = 1.4856rem */
  font-style: normal;
  font-weight: 700;
  line-height: 1.2855; /* 30.545px / 23.77px ≈ 1.2855 */
  letter-spacing: 0.2971rem; /* 4.754px / 16 = 0.2971rem */
  text-transform: uppercase;
}

.blogTitle {
  color: var(--Main-Black, #181717);
  font-family: "Inter", sans-serif;
  font-size: 1.4233rem; /* 22.773px / 16 = 1.4233rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.215; /* 27.669px / 22.773px ≈ 1.215 */
  letter-spacing: 0.0427rem; /* -0.683px / 16 = -0.0427rem */
  margin: 1rem 0;
  text-transform: uppercase;
}

.blogHeadline {
  color: var(--Main-Black, #181717);
  font-family: "Inter", sans-serif;
  font-size: 1.4233rem; /* 22.773px / 16 = 1.4233rem */
  font-style: normal;
  font-weight: 400;
  line-height: 1.215; /* 27.669px / 22.773px ≈ 1.215 */
  letter-spacing: -0.0427rem; /* -0.683px / 16 = -0.0427rem */
  margin-bottom: 1rem;
  max-height: 5.2699rem; /* 3 * 1.4233rem */
  overflow: hidden;
}

.badges {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 7rem;
  margin-right: 3rem;
}

.badge {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem; /* 12px / 16 = 0.75rem, 16px / 16 = 1rem */
  align-items: center;
  gap: 1rem; /* 16px / 16 = 1rem */
  border-radius: 6.25rem; /* 100px / 16 = 6.25rem */
  border: 1px solid
    var(--Base-Transparences-Black-black-5, rgba(22, 22, 25, 0.05));
  background: rgba(252, 172, 16, 0.2);
  margin: 0.2rem;
}

.badgeIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.2rem;
}

.badgeText {
  color: var(--1, #fcac10);
  font-family: "SF Pro Display", sans-serif;
  font-size: 1.125rem; /* 18px / 16 = 1.125rem */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.01125rem; /* -0.18px / 16 = -0.01125rem */
}

.iconButton {
  color: var(--Main-Black, #181717);
  font-family: Inter;
  font-size: 22.773px;
  font-style: normal;
  font-weight: 500;
  line-height: 121.5%; /* 27.669px */
  letter-spacing: -0.683px;
  border-bottom: 1px solid #181717;
  text-align: left;
  width: max-content;
  /* border: none; */
  /* background-color: none; */
}

.iconButton i {
  font-size: 1.5rem;
}

.img {
  width: 100%;
  height: auto;
}
.colTwo {
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .row {
    flex-direction: row;
  }
}

.col {
  display: flex;
  flex-direction: column;
  /* margin-left: 8rem; */
}
.colTwo {
  display: flex;
  flex-direction: column;
}
.title {
  color: #2d2d2d;
  font-family: "Inter", sans-serif;
  font-size: 3.5rem; /* Adjusted to relative unit */
  font-style: normal;
  font-weight: 600;
  line-height: 1.2; /* 120% */
  margin-bottom: 1rem;
}

.text {
  color: #2d2d2d;
  font-family: "Inter", sans-serif;
  font-size: 1.5rem; /* Changed from 32px to 1.5rem */
  font-style: normal;
  font-weight: 400;
  line-height: 1.3; /* 130% */
  margin-bottom: 1rem;
}

.button {
  align-self: start;
  display: inline-flex;
  padding: 0.9375rem 1.6875rem; /* 15px 27px converted to rem */
  align-items: center;
  gap: 0.625rem; /* 10px converted to rem */
  border-radius: 3.4375rem; /* 55px converted to rem */
  background: #2d2d2d;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem; /* 18px converted to rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.6; /* 160% */
}

.img1 {
  width: 35.5625rem;
  height: 28.1875rem;
}
.img2 {
  width: 40rem;
  height: 28rem;
}

.horizontalLine {
  margin-top: 6rem;
  border: 1px solid #181717;
  /* background: #181717; */
}
@media (max-width: 576px) {
  .title {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .text {
    font-size: 0.875rem; /* Smaller font size for mobile */
  }

  .containerOne,
  .containerTwo {
    margin: 4rem 1rem; /* Smaller margin for mobile */
    padding: 0 1rem; /* Smaller padding for mobile */
  }

  .img {
    width: 100%; /* Ensure the image takes full width of its container */
    height: auto; /* Maintain aspect ratio */
  }
  .button {
    padding: 0.01rem 0.02rem;
    margin-bottom: 1rem;
    font-size: 0.875rem; /* Further reduced font size for smaller devices */
    border-radius: 2rem; /* Further reduced border radius for smaller devices */
  }
  .horizontalLine {
    margin-top: 2rem;
    border: 1px solid #181717;
    /* background: #181717; */
  }
  .badges {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    margin-right: 3rem;
  }
}
