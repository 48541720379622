.title {
  color: var(--1, #fcac10);
  text-align: center;
  font-family: Inter;
  font-size: 2vw; /* Relative font size using vw */
  font-style: normal;
  font-weight: 600;
  line-height: 1.5; /* Relative line height using unitless value */
}
.subtitle {
  color: #1b152b;
  text-align: center;
  font-family: Inter;
  font-size: 3vw; /* Relative font size using vw */
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}
.header {
  color: #1b152b;
  font-family: Inter;
  font-size: 3.5vw; /* Relative font size using vw */
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}
.description {
  color: #646464;
  text-align: center;
  font-family: Manrope;
  font-size: 0.95vw; /* Equivalent relative font size using vw */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0288vw;
}
.imgDiv {
  /* Margin of 66px in relative unit */
  margin-top: 4.125vh; /* Margin of 66px in relative unit */
  margin-bottom: 4.125vh;
}
.customImage {
  flex-shrink: 0;
  border-radius: 91.296px;
  background: url("./drone.png") lightgray 50% / cover no-repeat;
  box-shadow: 0px 9.13px 54.778px -2.282px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(45.64812469482422px);
}

.researchContainer {
  padding: 8vh 22.3vw 0 22.3vw; /* Default padding for larger screens */

  @media (max-width: 767px) {
    padding: 8vh 5vw 0 5vw; /* Adjusted padding for smaller screens */
  }
}
