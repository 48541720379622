/* src/components/Team.module.css */

.teamContainer {
  /* Add any global styles to the container */
}

.teamHeader {
  color: var(--1, #fcac10);
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 2rem; /* 32px converted to rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.5; /* 48px converted to unitless line-height */
}

.subHead {
  color: #2d2d2d;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 4rem; /* 64px converted to rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.2; /* 76.8px converted to unitless line-height */
}

.card {
  margin: 1.25rem 0; /* 20px converted to rem */
}

.teamCard {
  position: relative;
}

.cardImg {
  width: 26.625rem; /* 426px converted to rem */
  height: 28.375rem; /* 454px converted to rem */
  object-fit: cover;
  margin: 1.25rem auto; /* 20px converted to rem */
  flex-shrink: 0;
  /* background: lightgray -396.515px 0.069px / 193.102% 120.716% no-repeat; */
}

.cardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: "Inter", sans-serif;
  padding: 0 1.25rem; /* 20px converted to rem */
}

.cardDetails {
  flex-grow: 1;
}

.cardTitle {
  margin-bottom: 0.625rem; /* 10px converted to rem */
  line-height: 1.2; /* 120% converted to unitless line-height */
  color: #000;
  font-family: Inter;
  font-size: 1.5rem; /* 24px converted to rem */
  font-style: normal;
  font-weight: 600;
}

.cardText {
  color: #000;
  font-family: Inter;
  font-size: 1rem; /* 16px converted to rem */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; /* 120% converted to unitless line-height */
}

.socialIcons {
  display: flex;
  gap: 0.625rem; /* 10px converted to rem */
  margin-left: auto;
}

.socialIcons a {
  color: #333;
  font-size: 1.375rem; /* 22px converted to rem */
  width: 2.75rem; /* 44px converted to rem */
  height: 2.75rem; /* 44px converted to rem */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease;
}

.socialIcons a:hover {
  color: var(--primary-color, #007bff);
}
.card {
  margin: 1.25rem 0; /* 20px converted to rem */
}

@media (min-width: 768px) {
  .card {
    margin: 0 0 0 3rem; /* 78px converted to rem */
  }
}
