/* HeroSection.module.css */

.main-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.hero-section {
  display: flex;
  align-items: center;
  position: relative;
  padding: 3vw 1vw;
}

.column {
  flex: 1;
  margin: 0 1vw;
}

.column img {
  max-width: 100%;
  height: auto;
}

.column:nth-child(2) {
  text-align: center;
}

.column:last-child {
  display: flex;
  justify-content: flex-end;
}

.hero-heading-div {
  width: 80vw;
  height: 19vw;
  flex-shrink: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 254, 251, 1),
    rgba(252, 172, 16, 0.2)
  );
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-heading {
  margin-top: 0;
  font-family: Inter;
  font-weight: bold;
  font-size: 5.5vw;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  height: 10vw;
  line-height: 120%;
}

.sol-text {
  color: #fcac10;
  font-style: italic;
}

.hero-section img.icon1 {
  position: absolute;
  bottom: 16vh; /* Adjusted for better positioning */
  left: 2vw; /* Adjusted for better positioning */
  z-index: 2;
  width: 20.73vw; /* Set width in vw */
  height: auto; /* Maintain aspect ratio */
  flex-shrink: 0;
}

.hero-section img.icon2 {
  position: absolute;
  bottom: 16vh; /* Adjusted for better positioning */
  right: 2vw; /* Adjusted for better positioning */
  z-index: 2;
  width: 20.73vw; /* Set width in vw */
  height: auto; /* Maintain aspect ratio */
}

.sub-title {
  color: #7c7c7c;
  text-align: center;
  font-family: Inter;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 0.7vw;
}

.sub-title-div {
  padding: 0 8.7vw;
}

.get-in-touch {
  padding: 1vw 2vw;
  font-size: 1vw;
  border-radius: 55px;
  background: #2d2d2d;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 160%;
  margin-right: 2rem;
}

.btn-section {
  margin-top: 3vw;
}

.discover {
  padding: 1vw 2vw;
  font-size: 1vw;
  border-radius: 55px;
  border: 1px solid #2d2d2d;
  color: #2d2d2d;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 160%;
}

@media (max-width: 768px) {
  .hero-section img.icon1,
  .hero-section img.icon2 {
    display: none;
  }

  .hero-heading-div {
    width: 80vw;
    height: 19vw;
    flex-shrink: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 254, 251, 1),
      rgba(252, 172, 16, 0.2)
    );
    position: relative;
    z-index: 1;
    margin-top: 3rem;
  }

  .get-in-touch {
    padding: 1vw 2vw;
    font-size: 1vw;
    border-radius: 55px;
    background: #2d2d2d;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-weight: 600;
    line-height: 160%;
    margin-right: 0.5rem;
  }
}
