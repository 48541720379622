.experienceContainer {
  position: relative;
  width: 100%;
  min-height: 529px; /* Use min-height for responsiveness */
  background-image: url("./exp-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fbc336;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Add padding for spacing around content */
  box-sizing: border-box; /* Ensure padding is included in width */
}
.row div {
  margin-left: 0.5rem;
}
.topRightImage,
.bottomLeftImage {
  position: absolute;
  width: 10%; /* Fixed width */
  height: 10%; /* Fixed height */
  background-size: cover;
  z-index: 1; /* Ensure images are above background */
}

.topRightImage {
  top: 0;
  right: 0;
  background-image: url("./top-right.png");
}

.bottomLeftImage {
  bottom: 0;
  left: 0;
  background-image: url("./bottom-left.png");
}

.contentContainer {
  margin: 2rem;
  z-index: 2;
  color: #181818;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 100%; /* Ensure content does not overflow */
}

.badge {
  background-color: #fff;
  padding: 8px 20px;
  font-size: 1rem; /* Adjust font size for smaller screens */
  font-weight: bold;
  /* display: inline-block; */
  margin-bottom: 10px;
  border-radius: 20px;
  text-transform: uppercase;
  width: max-content;

  /* text-align: left; */
}

.subHeader {
  text-align: left;
  font-size: 2.5rem; /* Adjust font size for smaller screens */
  font-weight: 700;
  line-height: 1.2; /* Adjust line height */
  margin-bottom: 20px; /* Increase space between elements */
}

.stat {
  font-size: 1rem; /* Adjust font size for smaller screens */
  line-height: 1.5; /* Adjust line height */
  margin-bottom: 20px; /* Increase space between elements */
  color: #181818;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 147.5%; /* 26.55px */
}

.progressCircles {
  display: flex;
  flex-wrap: wrap; /* Ensure circles wrap on smaller screens */
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust gap between circles */
}

.progressCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.circle {
  width: 11.617rem; /* Converted from 185.868px */
  height: 11.617rem; /* Converted from 185.868px */
  border-radius: 50%;
  border: 4px solid #e6e6e6;
  position: relative;
  margin-bottom: 10px;
}

.circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
  height: 100%;
  /* background-color: #fff;  */
  z-index: -1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.circleContent {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleTitle {
  font-size: 1.2rem; /* Adjust font size for smaller screens */
  font-weight: 600;
  margin-top: 10px; /* Increase space between circle and title */
}

@media (max-width: 768px) {
  .topRightImage,
  .bottomLeftImage {
    width: 160px; /* Adjust size for smaller screens */
    height: 160px; /* Adjust size for smaller screens */
  }

  .badge {
    font-size: 0.8rem; /* Adjust font size */
    padding: 8px 16px; /* Adjust padding */
  }

  .subHeader {
    font-size: 1.5rem; /* Adjust font size */
  }

  .stat {
    font-size: 0.9rem; /* Adjust font size */
  }

  .circleContent {
    font-size: 1rem; /* Adjust font size */
    width: 60px; /* Adjust size of circle content */
    height: 60px; /* Adjust size of circle content */
  }

  .circleTitle {
    font-size: 1rem; /* Adjust font size */
    margin-top: 8px; /* Adjust margin */
  }
}
