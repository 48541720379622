@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

/* Mobile-first styles */
.elemContainer {
  margin: 6vw auto;
}
.heading {
  width: 100%;
  text-align: center;
  margin: 0vw auto;
}
.main {
  background: #fdf7f4;
}
.main-head {
  color: #2d2d2d;
  font-family: Inter, sans-serif;
  font-size: 2.5rem; /* 40px */
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
}
.sub-heading {
  margin-top: 2rem;
  margin-bottom: 5rem;
  color: #646464;
  text-align: center;
  font-family: Manrope;
  font-size: 1.25rem; /* 20px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.059rem;
}
.container-elem-main {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background: #fdf7f4;
}

.left-col,
.right-col {
  width: 100%;
  margin-bottom: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem; /* 24px */
  margin-bottom: 1.5rem; /* 24px */
}

.logo-elem {
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
  margin-right: 1rem;
}

.title-elem {
  color: #2d2d2d;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Bebas Neue";
  font-size: 4.5vw; /* Using vw for font size */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.container-title-div {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem; /* 24px */
}

.subtitleElem {
  color: #1b152b;
  font-family: Inter, sans-serif;
  font-size: 2em;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.125rem; /* 2px */
  text-align: left;
}

.decDiv {
  width: 90vw; /* Adjusted width to be more responsive */
  max-width: 70vw; /* Cap the maximum width to 70vw */
  display: contents;
}

.description-elem {
  color: #646464;
  font-family: Inter, sans-serif;
  font-size: 1rem; /* 16px */
  font-weight: 400;
  line-height: 1.75rem; /* 28px */
  letter-spacing: -0.065rem; /* -1.046px */
  text-align: center;
  margin-bottom: 1.5rem; /* 24px */
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; /* 16px */
}

.btn-one {
  display: inline-flex;
  padding-bottom: 0.75rem; /* 12px */
  align-items: center;
  gap: 1rem; /* 16px */
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem; /* 20px */
  font-weight: 600;
  line-height: 1.5rem; /* 24px */
  border: none;
  background-color: #fdf7f4;
  border-bottom: 0.11rem solid #fcac10; /* 1.793px */
}

.main-image {
  width: 100%;
  height: auto;
}

.right-col {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the image and icons in mobile */
  margin-left: 1rem;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 1rem; /* 16px */
}

.icon {
  width: 7.5rem; /* 120px */
  height: auto;
  flex-shrink: 0;
}

/* Tablet and up styles */
@media (min-width: 768px) {
  .main-head {
    font-size: 4rem; /* 64px */
  }
  .sub-heading {
    margin-top: 2rem;
    margin-bottom: 5rem;
    color: #646464;
    text-align: center;
    font-family: Manrope;
    font-size: 1.75rem; /* Using rem for font size */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.059rem;
  }

  .container-elem-main {
    flex-direction: row;
    justify-content: space-between;
    padding: 2.5rem; /* 40px */
    margin-left: 6vw;
    margin-right: 6vw;
  }
  .left-col,
  .right-col {
    width: 45%;
    margin-bottom: 0;
  }
  .container-title-div {
    width: 60%;
  }
  .subtitleElem {
    font-size: 3vw; /* Using vw for font size */
    color: #1b152b;
    font-family: Inter;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.125rem; /* 2px */
    margin-top: 10vh; /* 75px */
  }
  .description-elem {
    width: 41.875vw; /* 41.875% of viewport width to approximately equal 670px */
    font-size: 1.5rem; /* 20px */
    line-height: 2rem; /* 32px */
    text-align: left; /* Align text to the left on desktop */
  }
  .header {
    justify-content: flex-start; /* Align logo and title to the left */
    width: 22.066vw; /* Approximately 278px relative to viewport width */
    height: 8.25vh; /* Approximately 66px relative to viewport height */
  }
  .container-title-div {
    width: 100%;
    text-align: left; /* Align title to the left */
  }
  .buttons {
    flex-direction: row;
    gap: 1.5rem; /* 24px */
    justify-content: flex-start; /* Align buttons to the left */
    margin-top: 30%;
  }
  .btn-one {
    font-size: 1.5rem; /* 24px */
  }
  .icon {
    width: 15rem; /* 240px */
  }
}
