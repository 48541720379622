.blogPost {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
}

.imageCol,
.contentCol {
  flex: 1;
  padding: 10px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;

  /* width: 630px;
  height: 630px; */
}

.date {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;

  color: #646464;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.5%; /* 25.7px */
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;

  color: #2d2d2d;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 33px */
}

.description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;

  color: #646464;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.5%; /* 25.7px */
}

.viewPostButton {
  padding: 10px 20px;
  /* background-color: #007bff; */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  color: #2d2d2d;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.5%; /* 23.13px */
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .imageCol,
  .contentCol {
    padding: 0;
  }

  .heading {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }

  .viewPostButton {
    padding: 8px 16px;
  }
}
