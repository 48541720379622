.faqContainer {
  padding: 20px;
  margin: 0 auto;
}

.head {
  color: #000;
  text-shadow: 0.667rem 1rem 2rem rgba(0, 0, 0, 0.05);
  font-family: Inter;
  font-size: 4.667rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.6rem;
  letter-spacing: -0.667px;
}

.header {
  color: var(--1, #fcac10);
  text-shadow: 0.667rem 1rem 2rem rgba(0, 0, 0, 0.05);
  font-family: Inter;
  font-size: 4.667rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.6rem;
  letter-spacing: -0.667px;
}

.accordion {
  /* border-top: 1px solid #ddd; */
  /* margin-left: 9.25rem;
  margin-right: 9.25rem; */
}

.faqItem {
  padding: 40px 0;
  /* width: 101.333rem; */
  height: auto; /* Changed from fixed height */
  overflow: hidden; /* Ensures smooth transition */
}

.questionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question {
  color: #000;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;
}

.answer {
  color: #818181;
  font-family: Montserrat;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 400;
  line-height: 2.114;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease; /* Added transition for animation */
  width: 81%;
}

.show {
  width: 81%;
  max-height: 20rem; /* Adjust based on expected content size */
  overflow: hidden;
}

.expandButton {
  font-size: 1.25rem;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.arrowIcon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease; /* Smooth transition for rotation */
}

.rotate {
  transform: rotate(180deg);
}

@media (min-width: 576px) {
  .faqContainer {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .faqContainer {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media (min-width: 1024px) {
  .faqContainer {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .faqContainer {
    margin-left: 9.25rem;
    margin-right: 9.25rem;
  }
}
@media (max-width: 768px) {
  .head {
    color: #000;
    text-shadow: 0.667rem 1rem 2rem rgba(0, 0, 0, 0.05);
    font-family: Inter;
    font-size: 3.667rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.6rem;
    letter-spacing: -0.667px;
  }

  .header {
    color: var(--1, #fcac10);
    text-shadow: 0.667rem 1rem 2rem rgba(0, 0, 0, 0.05);
    font-family: Inter;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 5.6rem;
    letter-spacing: -0.667px;
  }
  .question {
    color: #000;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
  }
  .show {
    width: 81%;
    max-height: 20rem; /* Adjust based on expected content size */
    max-height: 100rem;
  }
  .answer {
    color: #818181;
    font-family: Montserrat;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 2.114;
    /* max-height: 50%; */
    overflow: hidden;
    transition: max-height 0.5s ease;
    width: 100%;
  }
}
