/* Navbar component styles */

.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem; /* Adjust padding for top/bottom and left/right */
  background-color: #fffefb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbarLeft {
  display: flex;
  align-items: center;
}

.navbarLogo {
  max-width: 100%;
  height: auto;
  width: 8rem; /* Adjust logo size for smaller screens */
}

.navbarRight {
  display: flex;
  align-items: center;
}

.navbarList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbarItem {
  margin-right: 2.6rem; /* Adjust spacing between items */
}

.navbarItem a {
  color: #2d2d2d;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-size: 1.2rem; /* Adjust font size for smaller screens */
  font-weight: 400;
}

.contactButton {
  text-decoration: none;
  display: flex;
  padding: 0.9375rem 1.6875rem; /* Adjust padding for button */
  align-items: center;
  gap: var(--Gap-and-Padding-Positive-GapPadding-10, 10px);
  border-radius: 3.4375rem;
  border: 1px solid #2d2d2d;
  cursor: pointer;
  color: #fcac10;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin-right: 1rem; /* Adjust spacing */

  color: #2d2d2d;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.125rem; /* 18px converted to rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.6; /* 160% */
}

.navbarToggler {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.navbarTogglerIcon {
  display: inline-block;
  width: 1.5rem;
  height: 0.125rem;
  background-color: #333;
  position: relative;
}

.navbarTogglerIcon::before,
.navbarTogglerIcon::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 0.125rem;
  background-color: #333;
}

.navbarTogglerIcon::before {
  top: -0.4rem;
}

.navbarTogglerIcon::after {
  bottom: -0.4rem;
}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0.8rem 1.5rem; /* Adjust padding for top/bottom and left/right */
    background-color: #fffefb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 3rem;
  }

  .navbarLeft {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    z-index: 1001; /* Ensure logo is above the menu */
  }

  .navbarLogo {
    max-width: 100%;
    height: auto;
    width: 6rem; /* Adjust logo size for smaller screens */
  }

  .navbarRight {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 100%; /* Position below navbar */
    left: 0;
    background-color: #fffefb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Ensure it's above content */
  }

  .navbarRight.active {
    display: flex;
  }

  .navbarList {
    flex-direction: column;
    width: 100%;
  }

  .navbarItem {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    margin-right: 0;
  }

  .navbarItem a {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .contactButton {
    width: 100%;
    margin-top: 1rem;
    margin-right: 0;

    display: flex;
    padding: 0.9375rem 1.6875rem; /* Adjust padding for button */
    align-items: center;
    gap: var(--Gap-and-Padding-Positive-GapPadding-10, 10px);
    border-radius: 3.4375rem;
    border: 1px solid #2d2d2d;
    cursor: pointer;
    color: #fcac10;
    font-family: Inter, sans-serif;
    font-weight: 500;
    /* margin-right: 1rem;  */

    color: #2d2d2d;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.125rem; /* 18px converted to rem */
    font-style: normal;
    font-weight: 600;
    line-height: 1.6; /* 160% */
  }

  .navbarToggler {
    display: block;
    position: absolute;
    top: 0.8rem; /* Adjust top position as needed */
    right: 1rem; /* Adjust right position as needed */
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1001; /* Ensure it's above the menu */
  }

  .mobileMenuList {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobileMenuItem {
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  }

  .mobileMenuItem:last-child {
    margin-bottom: 0;
  }
}
