.container {
  margin-top: 7.8125rem;
}

.footer {
  position: relative;
  padding: 20px 0;
  color: #fff;
  overflow: hidden;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./footer-bg.png") center/cover no-repeat;
  filter: blur(10px); /* Apply the blur to the background image */
  z-index: -2; /* Ensure it stays behind the overlay */
}

.footer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.9); /* Apply the color overlay */
  z-index: -1; /* Ensure it stays behind the content but above the blurred background */
}

.footer .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer .logo {
  width: 100px;
  margin-bottom: 10px;
}

.footer .social-icons img {
  width: 30px;
  margin: 0 5px;
}

.footer .social-icons {
  margin-bottom: 15px;
}

.footer .button {
  margin-bottom: 20px;
  margin-top: 6.25rem; /* 100px / 16 */
}

.footer hr {
  width: 1px;
  height: 100%;
  background-color: #fff;
  border: none;
}

.footer .horizontalHr {
  width: 100%;
  border-top: 2px solid #fff;
  margin: 20px 0;
}

.footer .copyRight {
  text-align: center;
  margin-top: 10px;
  width: 100%;
}

.btn {
  display: flex;
  width: 15.294125rem; /* 244.706px / 16 */
  height: 3rem; /* 48px / 16 */
  padding: 0.7015625rem; /* 11.225px / 16 */
  justify-content: center;
  align-items: center;
  gap: 0.7015625rem; /* 11.225px / 16 */
  flex-shrink: 0;
  margin-bottom: 1.25rem; /* 20px / 16 */
  margin-top: 5rem; /* 100px / 16 */
  border-radius: 8.418px;
  background: var(
    --fhgb,
    linear-gradient(15deg, #f6af21 10.51%, #fef417 94.72%)
  );
  box-shadow: 18.942px 18.942px 7.717px 0px rgba(0, 0, 0, 0),
    12.277px 12.277px 7.015px 0px rgba(0, 0, 0, 0.03),
    7.015px 7.015px 5.963px 0px rgba(0, 0, 0, 0.1),
    3.157px 3.157px 4.209px 0px rgba(0, 0, 0, 0.17),
    0.702px 0.702px 2.455px 0px rgba(0, 0, 0, 0.2);
}

.btnText {
  color: #2d2d2d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 1.0523125rem; /* 16.837px / 16 */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.socialIcons img {
  margin-right: 1rem;
}

.fHeader {
  color: #fff;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 116%; /* 23.2px */
  position: relative;
  margin-bottom: 2rem;
}

.fHeader::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust this value to move the border further down */
  left: 50%;
  transform: translateX(-50%);
  width: 31.952px; /* 31.952px / 16 */
  height: 3.1px; /* 3.1px / 16 */
  border-radius: 20px; /* 20px / 16 */
  background: var(--1, #fcac10);
}

.footerUl {
  margin-top: 2rem;
}

.footerUl li {
  color: #fff;
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 147.5%; /* 23.6px */
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .footer .row {
    flex-direction: column;
    align-items: center;
  }

  .footer hr {
    display: none;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerUl {
    margin-top: 0;
  }

  .fHeader::after {
    left: 50%;
    transform: translateX(-50%);
  }
}
