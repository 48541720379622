.containerAbout {
  /* margin-top: 5vw; */
  width: 100%;
  padding: 2vw;
  box-sizing: border-box;
}
.rowContainer {
  padding: 0 5vw;
  width: 100%;
  display: flex;
}
.row {
  display: flex;

  /* flex-wrap: wrap; */
}

.col {
  padding: 1vw;
  box-sizing: border-box;
}

.logoCol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.textCol {
  width: 50%;
}
.logoAbout {
  width: 40vw;
  height: 40vw;
  max-width: 100%;
  max-height: 100%;
}

.aboutUsCol {
  display: flex;
}

.aboutUsCol h2 {
  color: #fcac10;
  text-shadow: 0px 0.25vw 0.25vw rgba(0, 0, 0, 0.25),
    0px 0.25vw 0.25vw rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 3vw;
  font-weight: 600;
  line-height: 4.5vw;
}

.aboutTitle {
  color: #2d2d2d;
  font-family: Inter;
  font-size: 7vw;
  font-weight: 600;
  line-height: 8vw;
  margin: 0;
  /* text-shadow: 0px 0.25vw 0.25vw rgba(0, 0, 0, 0.25),
    0px 0.25vw 0.25vw rgba(0, 0, 0, 0.25); */
}

.learnMoreDiv {
  margin-top: 2vw;
  display: flex;
  justify-content: center;
}

.learnMore {
  display: inline-flex;
  padding: 0.1vw 1.1vw;
  align-items: center;
  border-radius: 55px;
  background: #2d2d2d;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1vw;
  font-weight: 600;
  line-height: 3vw;
}

.content {
  width: 80%;
  color: #2d2d2d;
  font-family: Inter;
  font-size: 3vw;
  font-weight: 400;
  line-height: 4vw;
  margin: 2vw auto;
  text-align: center;
  /* width: 762px; */
}

@media (min-width: 768px) {
  .aboutUsCol {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .aboutTitle {
    text-align: left;
  }

  .logoAbout {
    width: 30vw;
    height: 30vw;
  }

  .aboutTitle {
    font-size: 4vw;
    line-height: 5vw;
  }

  .content {
    font-size: 2vw;
    line-height: 2.5vw;
    width: 100%;
    margin: 1vw auto;
    text-align: left;
  }

  .learnMoreDiv {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .logoAbout {
    width: 25vw;
    height: 25vw;
  }

  .aboutTitle {
    font-size: 3vw;
    line-height: 3vw;
  }
  .contentDiv {
    width: 5;
  }
  .content {
    font-size: 1.5vw;
    line-height: 2vw;
    width: 100%;
    margin: 1vw auto;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .aboutUsCol {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .aboutTitle {
    text-align: center;
  }
  .rowContainer {
    display: flex;
    flex-direction: column;
  }
  .logoCol,
  .textCol {
    width: 100%;
  }
  .content {
    width: 100%;
    color: #2d2d2d;
    font-family: Inter;
    font-size: 3vw;
    font-weight: 400;
    line-height: 4vw;
    margin: 2vw auto;
    text-align: center;
    /* width: 762px; */
  }
}
