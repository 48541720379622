.exploreContainer {
  position: relative;
}

.exploreImage {
  width: 100%;
  height: 0;
  padding-top: 28.6%; /* 549px / 1920px * 100 to maintain aspect ratio */
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("./explore-bg.jpg") lightgray 0px -365.33px / 100% 243.745% no-repeat;
  margin-top: 5.2%; /* 100px / 1920px * 100 to make it relative */
  position: relative;
  overflow: hidden; /* Prevent content overflow */
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 100%; /* Ensure content width is 100% of its container */
  padding: 1rem;
  box-sizing: border-box; /* Include padding in width calculation */
}

.title {
  color: var(--1, #fcac10);
  font-family: Inter, sans-serif;
  font-size: 1.5rem; /* 24px converted to rem for relative sizing */
  font-style: normal;
  font-weight: 600;
  line-height: 1.5; /* 150% of the font size */
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 2rem; /* Adjusted for smaller screens */
  font-style: normal;
  font-weight: 500;
  line-height: 1.5; /* 150% of the font size */
  margin-bottom: 1rem;
}

.button {
  display: inline-flex;
  padding: 0.9375rem 1.6875rem; /* 15px 27px converted to rem for relative sizing */
  align-items: center;
  gap: 0.625rem; /* 10px converted to rem for relative sizing */
  border-radius: 3.4375rem; /* 55px converted to rem for relative sizing */
  background: #fff;
  color: #2d2d2d;
  font-family: Inter, sans-serif;
  font-size: 1.125rem; /* 18px converted to rem for relative sizing */
  font-style: normal;
  font-weight: 600;
  line-height: 1.6; /* 160% of the font size */
  text-align: center;
  text-decoration: none;
}

.button:hover {
  background-color: #0056b3;
}

/* Media query for small devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
  .exploreImage {
    padding-top: 50%; /* Increase height for smaller screens */
    min-height: 300px; /* Minimum height to prevent image from disappearing */
  }

  .title {
    font-size: 1.5rem; /* 24px */
  }

  .subtitle {
    font-size: 2rem; /* Adjusted for smaller screens */
  }

  .button {
    font-size: 0.875rem; /* 14px for smaller screens */
    padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
  }
}

/* Media query for large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .subtitle {
    font-size: 3.5rem; /* 72px converted to rem for desktop */
  }
}
