.cardFeature {
  display: flex;
  padding: 2.0565rem 1.8696rem; /* 32.904px 29.913px */
  flex-direction: column;
  align-items: flex-start;
  gap: 1.8696rem; /* 29.913px */
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.7478rem; /* 11.965px */
  border: 0.0935rem solid #ddd; /* 1.496px */
  background: #fff7e0;
  box-shadow: 0px 0.1875rem 0.5rem 0px rgba(0, 0, 0, 0.24); /* 3px 8px */
  margin: 1rem; /* 16px */
}
.title {
  color: #2d2d2d;
  text-align: center;
  font-family: Inter;
  font-size: 60.616px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cardTitle {
  color: #2d2d2d;
  font-family: "Inter", sans-serif;
  font-size: 1.495625rem; /* 23.93px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.08975rem; /* -1.436px */
}
.cardText {
  color: #646464;
  font-family: "Manrope", sans-serif;
  font-size: 1.495625rem; /* 23.93px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.070125rem; /* -1.122px */
}
.containerFeat {
  padding: 5rem 6.25rem; /* 80px 100px */
  margin-top: 20px;
  border: none;
}
.rowCard {
  margin-top: 4em;
}
@media (max-width: 768px) {
  .containerFeat {
    padding: 2.5rem 3.125rem; /* 40px 50px */
  }
}

@media (max-width: 576px) {
  .containerFeat {
    padding: 1.25rem 1.5625rem; /* 20px 25px */
  }
}

.container {
  padding: 5rem 6.25rem;
  margin-top: 20px;
}
.title {
  color: #2d2d2d;
  text-align: center;
  font-family: Inter;
  font-size: 3.7885rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .container {
    padding: 2.5rem 3.125rem;
  }
  .title {
    color: #2d2d2d;
    text-align: center;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 1.25rem 1.5625rem;
  }
}
