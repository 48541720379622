@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap");
.wrapper {
  /* margin-left: 18.75%;
  margin-right: 18.75%; */
  background: #fff7df;
}
/* Existing styles... */

.background {
  width: 100%;
  height: 32.2916667vw; /* Equivalent to 620px for 1920px width */
  background-image: url("./about-bg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
li {
  list-style-type: none;
}
.overlay {
  display: inline-flex;
  padding: 2vw 3.125vw 2vw 3.125vw; /* Relative units based on viewport width */
  flex-direction: column;
  align-items: flex-start;
  gap: 0.52vw; /* Relative to 10px for 1920px width */
  border-radius: 1.51vw; /* Relative to 29px for 1920px width */
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5.5px);
  box-shadow: 0 0.52vw 1.04vw rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: auto;
  text-align: left;
}
.overlayCard {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.52vw; /* Relative to 10px for 1920px width */
  /* backdrop-filter: blur(5.5px); */
  /* max-width: 90%; */
  /* width: auto; */
  text-align: left;

  border-radius: 1.25rem;
  border: 1px solid #eaeaea;
  background: #fff;
  display: flex;
  padding: 2.25rem 1.4375rem;
  align-items: flex-start;
  gap: 0.625rem;

  width: 17rem;
  height: 20.375rem;
}
.badge {
  display: inline-flex;
  padding: 0.5em 1.5em;
  align-items: center;
  gap: 0.5em; /* Adjust gap as needed */
  border-radius: 20px;
  background: #fcac10;
  color: white;
  font-size: 1em;
}

.subTextCard {
  color: #595566;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 147.5%; /* 23.6px */
}

.badgeText {
  color: #181818;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 147.5%; /* 23.6px */
  letter-spacing: 2.32px;
  text-transform: uppercase;
}

h1 {
  font-size: 2em;
  margin: 0;
}

.aboutUsValuesStat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-radius: 1.51vw;
  margin-left: 18.75%;
  margin-right: 18.75%;
  background: #fff7df;
}

.statContent {
  flex: 1;
}

.statBadge {
  display: inline-flex;
  padding: 0.5em 1.5em;
  align-items: center;
  gap: 0.5em;
  border-radius: 20px;
  background: #fcac10;
  color: white;
  font-size: 1em;
}

.statHeading {
  color: #181818;
  font-family: Cabin;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.29em; /* approximately 52.2px */
}

.statDescription {
  color: #595566;
  font-family: Lato;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.475em; /* approximately 23.6px */
}

.statButton {
  display: flex;
  padding: 0.9375rem 1.6875rem; /* 15px and 27px in rem */
  align-items: center;
  gap: var(
    --Gap-and-Padding-Positive-GapPadding-10,
    0.625rem
  ); /* 10px in rem */
  border-radius: 3.4375rem; /* 55px in rem */
  background: #2d2d2d;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem; /* 18px */
  font-style: normal;
  font-weight: 600;
  line-height: 1.8; /* relative unit, approximately 28.8px */
}

.statButton:hover {
  background-color: #e09800;
}

/* Additional Section Styles */
.additionalSection {
  padding: 2rem 0;
}

.sectionBadge {
  display: inline-block;
  padding: 0.104vw 0.521vw; /* Equivalent to 2px 10px for 1920px width */
  background-color: #fcac10;
  color: #fff;
  border-radius: 1.042vw; /* Equivalent to 20px for 1920px width */
  text-align: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 1.064vw; /* Equivalent to 20.42px for 1920px width */
  font-style: normal;
  font-weight: 400;
  line-height: 1.57vw; /* Equivalent to 30.12px for 1920px width */
  letter-spacing: 0.154vw; /* Equivalent to 2.961px for 1920px width */
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.aboutLi {
}
.subHead {
  color: #181818;
  font-family: Inter;
  font-size: 3.59em; /* Relative unit equivalent */
  font-style: normal;
  font-weight: 700;
  line-height: 1.16em; /* Relative unit equivalent */
  text-wrap: nowrap;
}
.subHeadCard {
  color: #181818;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 116%; /* 23.2px */
}

.additionalSection h3 {
  color: #595566;
  font-family: "Inter", sans-serif;
  font-size: 1.064vw; /* Equivalent to 20.42px for 1920px width */
  font-style: normal;
  font-weight: 400;
  /* line-height: 1.28em; */
  /* line-height: 4vw; */
  margin-bottom: 2.5rem;
  /* letter-spacing: 0.25em; */
}

.additionalSection p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-right: 1rem;
}

.tick {
  margin-right: 1rem;
}

/* New CSS for AboutStats Component */
.aboutStatsCard {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 0;
}

.aboutStats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2rem 16%;
}
.statItem {
  text-align: center;
  /* flex: 1; */
  padding: 20px;
  margin: 10px;
  /* background-color: #f0f0f0; */
  border-radius: 1.042vw; /* Equivalent to 20px for 1920px width */
}
.statItemCard {
  text-align: center;
  /* flex: 1; */
  /* padding: 20px; */
  /* margin: 10px; */
  /* background-color: #f0f0f0; */
  border-radius: 1.042vw; /* Equivalent to 20px for 1920px width */
  margin-left: 3rem;
}

.number {
  color: #181818;
  font-family: Cabin;
  font-size: 4.121rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.16; /* or 1.16em */
}

.text {
  margin-top: 10px;

  color: #181818;
  font-family: Cabin;
  font-size: 1.484rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.16; /* or 1.16em */
}

/* New Styles for AboutUsValues Component */

.statContent {
  flex: 1;
  margin-top: 5em;
  /* margin-bottom: 5em; */
}

.statButton {
  flex-shrink: 0; /* Prevent the button from shrinking */
}

/* about history */
.historyAboutWrapper {
  text-align: center;
  /* background: #fff7df; */
  padding: 80px 0; /* Adjusted as needed */
}

.historyAboutBadge {
  display: inline-block;
  padding: var(--Gap-and-Padding-Positive-GapPadding-0, 0px) 15.748px;

  border-radius: 20px;
  background: #fcac10;
  margin-bottom: 1rem;
  gap: 0.8rem;
  color: #fff;
  font-family: Inter;
  font-size: 1.431rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.476; /* or 1.476em */
  letter-spacing: 0.145em;
  text-transform: uppercase;
}

.historyAboutHeading {
  margin-bottom: 1rem;

  color: #181818;
  text-align: center;
  font-family: Inter;
  font-size: 4.027rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.16; /* or 1.16em */
}

.historyAboutDescription {
  margin-bottom: 2rem;
  padding: 0 16%;

  color: #595566;
  text-align: center;
  font-family: Inter;
  font-size: 1.432rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.476; /* or 1.476em */
}

.historyAboutImageWrapper {
  position: relative;
  display: inline-block;
}

.historyAboutImage {
  width: 80%; /* 80% of the parent element's width */
  height: 40%; /* 40% of the parent element's height */
  flex-shrink: 0;
  border-radius: 2.5rem;
  /* opacity: 0.4; */
}

.topRightSquare,
.bottomLeftSquare {
  position: absolute;
  /* width: 100px;
  height: 100px; */
  z-index: -1; /* Place behind the image */
  width: 15rem; /* Assuming a base font size of 16px, this is approximately 240px */
  height: 16rem; /* Assuming a base font size of 16px, this is approximately 256px */
  flex-shrink: 0;
  border-radius: 1.75rem; /* Assuming a base font size of 16px, this is approximately 28px */
  background: #fcac10;
}

.topRightSquare {
  top: -32px;
  right: 102px;
}

.bottomLeftSquare {
  bottom: -41px;
  left: 91px;
}
/* vision card */
@media (max-width: 1200px) {
  .wrapper {
    margin-left: 15%; /* Adjust margin for smaller screens */
    margin-right: 15%; /* Adjust margin for smaller screens */
  }

  .overlay {
    padding: 2vw 2.5vw 2.5vw 2.5vw;
    gap: 0.42vw;
    border-radius: 1.2vw;
  }

  .badge {
    font-size: 0.9em;
    padding: 0.4em 0.9em;
  }

  h1 {
    font-size: 1.8em;
  }
  .historyAboutBadge {
    font-size: 0.9em;
    padding: 0.4em 0.9em;
  }

  .historyAboutHeading {
    font-size: 1.8em;
  }

  .historyAboutDescription {
    font-size: 1.4em;
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-left: 11.72%; /* Adjust margin for smaller screens */
    margin-right: 11.72%; /* Adjust margin for smaller screens */
  }

  .overlay {
    padding: 1.5vw 2vw 2vw 2vw;
    gap: 0.3vw;
    border-radius: 1vw;
  }

  .badge {
    font-size: 0.8em;
    padding: 0.3em 0.8em;
  }

  h1 {
    font-size: 1.5em;
  }

  .aboutUsValuesStat {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    margin-left: 0.75%;
    margin-right: 0.75%;
    background: #fff7df;
  }
  .statContent {
    flex: 1;
    margin-top: 0em;
    margin-bottom: 0em;
  }
  .statContent {
    margin-bottom: 1.5rem;
  }

  .statBadge {
    font-size: 1em;
    padding: 0.5em 1.2em;
  }

  .statHeading {
    font-size: 2em;
  }

  .statDescription {
    font-size: 1em;
  }

  .statButton {
    padding: 0.9375rem 1.6875rem; /* 15px and 27px in rem */
    align-items: center;
    gap: var(
      --Gap-and-Padding-Positive-GapPadding-10,
      0.625rem
    ); /* 10px in rem */
    border-radius: 3.4375rem; /* 55px in rem */
    background: #2d2d2d;
  }

  .additionalSection h2 {
    font-size: 1.5rem;
  }

  .additionalSection h3 {
    font-size: 1rem;
  }

  .sectionBadge {
    font-size: 0.9rem;
  }

  .statButton {
    padding: 0.7em 1.2em;
    font-size: 0.9em;
    margin-top: 0.8rem;
  }
  .additionalSection h2 {
    font-size: 1.25rem;
  }

  .additionalSection h3 {
    font-size: 0.9rem;
  }
  .badgeDivVision {
    display: flex;
    justify-content: center;
  }
  .sectionBadge {
    font-size: 0.8rem;
    /* display: flex; */

    /* align-items: center; */
  }

  .statBadge {
    font-size: 0.8em;
    padding: 0.4em 0.8em;
    display: inline-flex;
    padding: 0.5em 1.5em;
    align-items: center;
    gap: 0.5em;
    border-radius: 20px;
    background: #fcac10;
    color: white;
    font-size: 1em;
  }

  .statHeading {
    font-size: 1.8em;
  }

  .statDescription {
    font-size: 0.9em;
  }

  .statButton {
    padding: 0.7em 1.2em;
    font-size: 0.9em;
    margin-top: 0.8rem;
  }
  .overlayCard {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.52vw; /* Relative to 10px for 1920px width */
    /* backdrop-filter: blur(5.5px); */
    /* max-width: 90%; */
    /* width: auto; */
    text-align: left;

    border-radius: 1.25rem;
    border: 1px solid #eaeaea;
    background: #fff;
    display: flex;
    padding: 2.25rem 1.4375rem;
    align-items: flex-start;
    gap: 0.625rem;

    width: 17rem;
    height: 20.375rem;
    margin-top: 1rem;
  }
  .statItemCard {
    text-align: center;
    /* flex: 1; */
    /* padding: 20px; */
    /* margin: 10px; */
    /* background-color: #f0f0f0; */
    border-radius: 1.042vw; /* Equivalent to 20px for 1920px width */
    margin-left: 0rem;
  }

  /* about history */
  .historyAboutWrapper {
    padding: 60px 0; /* Adjusted as needed */
  }

  .historyAboutBadge {
    font-size: 0.8em;
    padding: 0.3em 0.8em;
  }

  .historyAboutHeading {
    font-size: 1.5em;
  }

  .historyAboutDescription {
    font-size: 1.2em;
    padding: 0 5%;
  }

  .historyAboutImage {
    max-width: 90%; /* Adjust size as needed */
  }

  .topRightSquare,
  .bottomLeftSquare {
    width: 70px; /* Adjust size as needed */
    height: 70px; /* Adjust size as needed */
  }

  .topRightSquare {
    top: -10px; /* Adjust position as needed */
    right: -10px; /* Adjust position as needed */
  }

  .bottomLeftSquare {
    bottom: -10px; /* Adjust position as needed */
    left: -10px; /* Adjust position as needed */
  }
  .background {
    width: 100%;
    height: 32.2916667vw; /* Equivalent to 620px for 1920px width */
    background-image: url("./about-bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
}

@media (max-width: 576px) {
  .wrapper {
    margin-left: 0%; /* Adjust margin for smallest screens */
    margin-right: 0%; /* Adjust margin for smallest screens */
  }

  .overlay {
    padding: 1vw 1.5vw 1.5vw 1.5vw;
    gap: 0.2vw;
    border-radius: 0.8vw;
  }

  .badge {
    font-size: 0.7em;
    padding: 0.2em 0.7em;
  }

  h1 {
    font-size: 1.2em;
  }
  .historyAboutWrapper {
    padding: 40px 0; /* Adjusted as needed */
  }

  .historyAboutBadge {
    font-size: 0.7em;
    padding: 0.2em 0.7em;
  }

  .historyAboutHeading {
    font-size: 1.2em;
  }

  .historyAboutDescription {
    font-size: 1em;
    padding: 0 2%;
  }

  .historyAboutImage {
    max-width: 80%; /* Adjust size as needed */
  }

  .topRightSquare,
  .bottomLeftSquare {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
  }

  .topRightSquare {
    top: -5px; /* Adjust position as needed */
    right: -5px; /* Adjust position as needed */
  }

  .bottomLeftSquare {
    bottom: -5px; /* Adjust position as needed */
    left: -5px; /* Adjust position as needed */
  }
}
