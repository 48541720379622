/* Container styles */
.contactUsContainer {
  max-width: 100%;
  padding: 1.25rem; /* 20px in rem */
  border-radius: 0.5rem; /* 8px in rem */
  background: #fffefb;
}

/* Banner styles */
.banner {
  margin-top: 3rem;
  text-align: center;
  padding: 1.25rem; /* 20px in rem */
  background: #fffefb;
  color: #2d2d2d;
  border-top-left-radius: 0.5rem; /* 8px in rem */
  border-top-right-radius: 0.5rem; /* 8px in rem */
}

.banner h2 {
  font-family: Inter;
  font-size: 3.125rem; /* 50px in rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.1; /* 110% in relative unit */
}

.banner h2 span {
  font-size: 1.5rem; /* 24px in rem */
  margin-left: 0.625rem; /* 10px in rem */
}

/* Form container styles */
.contactFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contactForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem; /* 20px in rem */
  margin-top: 1.25rem; /* 20px in rem */
  width: 100%;
  max-width: 76.25em; /* Equivalent to 1220px if 1em = 16px */
}

/* Individual form row styles */
.formRow {
  margin-bottom: 0.9375rem; /* 15px in rem */
}

/* Span full width for the last field */
.contactForm > .formRow:last-child {
  grid-column: span 2;
}

.textAreaRow {
  grid-column: span 2;
}

.formBtnRow {
  display: flex;
  justify-content: center;
  grid-column: span 2;
}

/* Label styles */
label {
  display: block;
  margin-bottom: 0.5rem; /* 8px in rem */
  font-weight: bold;
  margin-bottom: 1.5em;
}

/* Input and textarea styles */
input,
textarea {
  width: 100%;
  padding: 0.75rem; /* 12px in rem */
  font-size: 1rem; /* 16px in rem */
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* 4px in rem */
}

/* Send message button styles */
.sendMessageButton {
  display: flex;
  padding: 1.071em 1.929em; /* Equivalent to roughly 15px 27px */
  align-items: center;
  gap: 0.625em; /* Equivalent to roughly 10px */
  border-radius: 3.438em; /* Equivalent to roughly 55px */
  background: #2d2d2d;
  color: #fff;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem; /* 18px in rem */
  font-style: normal;
  font-weight: 600;
  line-height: 1.6;
  margin-top: 4.4em;
}

.inputArea {
  border-radius: var(--Corner-Radius-Radius-10, 10px);
  border: 1px solid #dcdcdc;
  background: #fff;
}

.labelText {
  color: #2d2d2d;
  font-family: Inter;
  font-size: 1.25em; /* Equivalent to 20px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* 150% converted to a unitless number */
  text-transform: capitalize;
}

.textArea {
  width: 100%;
  height: 12.125em; /* Equivalent to 194px if 1em = 16px */
  flex-shrink: 0;
  border-radius: 0.625em; /* Equivalent to 10px if 1em = 16px */
  border: 0.0625em solid #dcdcdc; /* Equivalent to 1px if 1em = 16px */
  background: #fff;
  grid-column: span 2; /* Ensure the textarea spans both columns */
}

/* Enquiry.module.css */
a {
  text-decoration: none;
}
.enquiryContainer {
  display: flex;
  justify-content: center; /* Center align content horizontally */
  align-items: center; /* Center align content vertically */
  height: 32.7rem; /* Using rems as a relative unit */
  /* padding: 6.8rem 7.8rem;  */
  gap: 1rem; /* Adjust the gap between elements in rem */
  flex-direction: column;
  border-radius: 2rem; /* Rounded corners in rem */
  background: #2d2d2d;
  color: #fff;
  margin-right: 35.125rem; /* 562px divided by 16 = 35.125rem */
  margin-left: 38.75rem; /* 620px divided by 16 = 38.75rem */
  margin-top: 4.25rem; /* 115px divided by 16 = 7.1875rem */
  margin-bottom: 4.25rem; /* 100px divided by 16 = 6.25rem */
}

.enquiryContent {
  display: flex;
  gap: 102px; /* Adjust the gap between columns */
}

.enquiryInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.enquiryRow {
  display: flex;
  gap: 6.375rem;
}

.enquiryCol {
  /* flex: 1; */
  text-align: left; /* Align text within columns to the left */
}

.enquiryHeading {
  color: #fff; /* Color remains as is */
  font-family: "Inter", sans-serif; /* Assuming Inter is a web font */
  font-size: 1.875rem; /* Equivalent to 30px assuming 16px base font size */
  font-style: normal; /* Font style remains as is */
  font-weight: 400; /* Font weight remains as is */
  line-height: 1.1; /* 110% in relative unit, equivalent to 1.1 */
}
.enquiryCol p {
  color: #a1a1a1;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
  text-decoration: none;
}
.enquiryCol a {
  color: #a1a1a1;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
  text-decoration: underline;
}
.enquiryCol a:hover {
  color: #fcac10;
}
.pDiv {
  margin-top: 1.625rem;
}
/* Responsive styles for smaller screens */
@media (max-width: 76.25em) {
  .contactForm {
    grid-template-columns: 1fr;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .contactForm > .formRow:last-child,
  .textAreaRow,
  .formBtnRow {
    grid-column: span 1;
  }
  .enquiryContainer {
    padding: 4rem; /* Adjust padding for smaller screens */
    margin-right: 0; /* Remove right margin on smaller screens */
    margin-left: 0; /* Remove left margin on smaller screens */
  }

  .enquiryContent {
    gap: 3rem; /* Adjust the gap between columns for smaller screens */
  }
}

@media (max-width: 48rem) {
  .banner h2 {
    font-size: 1.5rem; /* 24px in rem */
  }

  .contactForm {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .enquiryRow {
    flex-direction: row; /* Change to row layout on smaller screens */
    gap: 1.5rem; /* Adjust gap between columns */
  }

  .enquiryCol {
    max-width: calc(50% - 1rem); /* Two columns per row */
    margin-bottom: 1.5rem; /* Adjust margin between columns */
  }

  .enquiryInner {
    align-items: flex-start; /* Align items to the start */
    text-align: left; /* Align text to the left */
  }

  .enquiryHeading {
    font-size: 1.5rem; /* Adjust font size for headings on smaller screens */
  }
}
