.testimonialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
}

.header {
  font-size: 2rem; /* Equivalent to 32px */
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 1.5; /* Relative unit equivalent to 150% */
  color: var(--1, #fcac10);
}

.subHeader {
  font-size: 4rem; /* Equivalent to 64px */
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 1.2; /* Relative unit equivalent to 120% */
  color: #2d2d2d;
  margin-bottom: 20px;
}

.testimonialCard {
  width: 100%;
  max-width: 1391.732px; /* Max width for large screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 4vw 3.063vw 3vw 3.188vw;

  border-radius: 42.094px;
  border: 1.315px solid #dcdcdc;
  background: #fffaed;
}

.card-body {
  width: 100%;
}

.testimonialImage {
  width: 100%;
  height: 100%;
  max-width: 100%; /* Ensure image does not exceed its container */
  max-height: 440.671px; /* Set max-height for large screens */
  object-fit: cover; /* Maintain aspect ratio and cover container */
  border-radius: 21.047px; /* Relative border-radius based on height */
}
.text {
  color: #757575;
  font-family: "Inter", sans-serif;
  font-size: 1vw; /* Equivalent to 21.047px in relative units */
  font-style: normal;
  font-weight: 400;
  line-height: 1.875; /* Equivalent to 30px in relative units */
  letter-spacing: -0.0792vw; /* Equivalent to -1.263px in relative units */
}
.name {
  color: #1b152b;
  font-family: "Inter", sans-serif;
  font-size: 1.973vw; /* Equivalent to 31.57px in relative units */
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* This will default to the browser's default line-height */
  letter-spacing: -0.0792vw; /* Equivalent to -1.263px in relative units */
}
.position {
  color: #1b152b;
  font-family: "Inter", sans-serif;
  font-size: 1.638vw; /* Equivalent to 26.309px in relative units */
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* This will default to the browser's default line-height */
  letter-spacing: -0.0792vw; /* Equivalent to -1.263px in relative units */
}
.prevButton {
  display: flex;
  width: 4vw; /* Equivalent to 72.494px in relative units */
  height: 4vw; /* Equivalent to 72.494px in relative units */
  padding: var(--Gap-and-Padding-Positive-GapPadding-0, 1.416vw)
    var(--Gap-and-Padding-Positive-GapPadding-0, 1.417vw)
    var(--Gap-and-Padding-Positive-GapPadding-0, 1.417vw)
    var(--Gap-and-Padding-Positive-GapPadding-0, 1.417vw); /* Adjusted to relative units */
  justify-content: center;
  align-items: center;
  border-radius: 1314.121px;
  border: 1.315px solid #2d2d2d;
  opacity: 0.801;
  margin: 0.5em;
}
.nextButton {
  margin: 0.5em;

  display: flex;
  width: 4vw; /* 72.494px in relative units */
  height: 4vw; /* 72.494px in relative units */
  padding: var(--Gap-and-Padding-Positive-GapPadding-0, 1.416vw)
    var(--Gap-and-Padding-Positive-GapPadding-0, 1.417vw)
    var(--Gap-and-Padding-Positive-GapPadding-0, 1.417vw)
    var(--Gap-and-Padding-Positive-GapPadding-0, 1.417vw);
  justify-content: center;
  align-items: center;
  border-radius: 1314.121px;
  opacity: 0.801;
  background: #2d2d2d;
}

@media (min-width: 992px) {
  .testimonialCard {
    flex-direction: row;
    justify-content: center; /* Center align card content on large screens */
    /* height: 588px; */
  }

  .testimonialImage {
    max-width: 651.141px; /* Set max-width for desktop */
  }

  .card-body {
    margin-left: 20px; /* Add space between image and text on large screens */
    flex: 1; /* Allow card body to expand */
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end; /* Right align buttons */
    margin-top: 10px;
  }
  .subHeader {
    font-size: 3rem; /* Adjust font size for smaller screens */
    line-height: 1.5; /* Adjust line height for smaller screens if needed */
    margin-bottom: 10px; /* Adjust margin bottom for smaller screens if needed */
  }
}
@media (max-width: 768px) {
  .subHeader {
    font-size: 2rem; /* Equivalent to 64px */
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 1.2; /* Relative unit equivalent to 120% */
    color: #2d2d2d;
    margin-bottom: 20px;
  }
  .text {
    color: #757575;
    font-family: "Inter", sans-serif;
    font-size: 3vw; /* Equivalent to 21.047px in relative units */
    font-style: normal;
    font-weight: 400;
    line-height: 1.875; /* Equivalent to 30px in relative units */
    letter-spacing: -0.0792vw; /* Equivalent to -1.263px in relative units */
  }
  .name {
    color: #1b152b;
    font-family: "Inter", sans-serif;
    font-size: 5vw; /* Equivalent to 31.57px in relative units */
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* This will default to the browser's default line-height */
    letter-spacing: -0.0792vw; /* Equivalent to -1.263px in relative units */
  }
  .position {
    color: #1b152b;
    font-family: "Inter", sans-serif;
    font-size: 3.638vw; /* Equivalent to 26.309px in relative units */
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* This will default to the browser's default line-height */
    letter-spacing: -0.0792vw; /* Equivalent to -1.263px in relative units */
  }
  .prevButton {
    width: 10vw; /* Adjusted width for smaller screens */
    height: 10vw; /* Adjusted height for smaller screens */
    padding: var(--Gap-and-Padding-Positive-GapPadding-0, 2vw)
      var(--Gap-and-Padding-Positive-GapPadding-0, 2vw)
      var(--Gap-and-Padding-Positive-GapPadding-0, 2vw)
      var(--Gap-and-Padding-Positive-GapPadding-0, 2vw); /* Adjusted padding for smaller screens */
    border-radius: 50%; /* Make it circular on smaller screens */
    margin: 0.5em; /* Retain margin for spacing */
  }
  .nextButton {
    width: 10vw; /* Adjusted width for smaller screens */
    height: 10vw; /* Adjusted height for smaller screens */
    padding: var(--Gap-and-Padding-Positive-GapPadding-0, 2vw)
      var(--Gap-and-Padding-Positive-GapPadding-0, 2vw)
      var(--Gap-and-Padding-Positive-GapPadding-0, 2vw)
      var(--Gap-and-Padding-Positive-GapPadding-0, 2vw); /* Adjusted padding for smaller screens */
    border-radius: 50%; /* Make it circular on smaller screens */
  }
  .buttonGroup {
    display: flex;
  }
}
