.seenContainer {
  padding: 20px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: var(--1, #fcac10);
  font-family: Inter;
  font-size: 2rem; /* Equivalent to 32px */
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.logoRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.logoCol {
  flex-basis: calc(25% - 10px);
  margin-bottom: 20px;
}

.logo {
  max-width: 100%;
  height: auto;
}
