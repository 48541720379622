.containerMain {
  padding: 5% 15%;
  text-align: center;
}

.badge {
  display: inline-block;
  width: 132.598px;
  height: 37.885px;
  border-radius: 27.783px;
  border: 1.263px solid var(--1, #fcac10);
  background: rgba(252, 172, 16, 0.1);
  color: var(--1, #fcac10);
  text-align: center;
  font-family: Manrope;
  font-size: 20.205px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.947px;
}

.heading {
  color: #2d2d2d;
  text-align: center;
  font-family: Inter;
  font-size: 3em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1.5rem;
}

.description {
  color: #646464;
  text-align: center;
  font-family: Manrope;
  font-size: 1.263rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.059rem;
  margin-top: 1.5rem;
}

.cardValues {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  border-radius: 20.205px;
  border: 1.263px solid #d5d5d5;
  background: #fffefb;
  padding-left: 5.57%;
  padding-top: 4%;
  padding-bottom: 0;
  padding-right: 5.57%;
  align-items: flex-start;
}

.cardContainer {
  flex: 1 1 calc(33.333% - 1rem);
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  position: relative;
  border: none;
  margin-bottom: 4rem; /* Default margin-bottom for large screens */
}

.card img {
  max-width: 50px;
  margin-bottom: 0.5rem;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 1rem;
}

.hr-vertical {
  border-left: 1px solid #dee2e6;
  height: auto;
  position: absolute;
  right: -0.5rem;
  top: 0;
  bottom: 0;
}

.cardTitle {
  color: #1b152b;
  text-align: center;
  font-family: Inter;
  font-size: 1.894rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cardDescription {
  color: var(--Text-Grey, #7a7d9c);
  text-align: center;
  font-family: Manrope;
  font-size: 1.263rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.059rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .containerMain {
    padding: 5% 10%; /* Adjusted padding for medium screens */
  }

  .cardContainer {
    flex: 1 1 calc(50% - 1rem); /* Two cards per row on medium screens */
    margin-bottom: 0; /* Remove margin-bottom for medium screens */
  }

  .heading {
    font-size: 2em; /* Adjusted font size for headings on medium screens */
    margin-top: 1.5rem;
  }
}

@media (max-width: 576px) {
  .containerMain {
    padding: 5% 5%; /* Adjusted padding for small screens */
  }

  .cardContainer {
    flex: 1 1 calc(100% - 1rem); /* One card per row on small screens */
    margin-bottom: 0; /* Remove margin-bottom for small screens */
  }
}
