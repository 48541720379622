/* Blog.module.css */

.blogContainer {
  width: 100%;
  /* max-width: 1920px; */
  height: auto;
  background-color: #2d2d2d;
  margin: 100px auto; /* Center container with 100px margin on top */
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 5rem 0 5rem 6.25rem;
}

.blogTitle {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 4rem; /* Equivalent to 64px (relative unit) */
  font-style: normal;
  font-weight: 500;
  line-height: 1.25; /* 80px / 64px = 1.25 (relative unit) */
  letter-spacing: -0.03125em; /* -0.5px / 64px = -0.03125em (relative unit) */
}

.blogButton {
  width: 25rem; /* 565.734px / 16px = 35.358rem */
  height: 5.188rem; /* 83px / 16px = 5.188rem */
  border-radius: 122.059px;
  background: rgba(245, 245, 255, 0.1);
  box-shadow: 0px 6.103px 0px -4.882px rgba(255, 255, 255, 0.1) inset;
  color: #fff;
  margin: 2rem 0;
  /* transition: background-color 0.3s ease; */
}
.blogText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 0.9878125rem; /* 15.805px / 16px = 0.9878125rem */
  font-style: normal;
  font-weight: 400;
  line-height: 1.61345; /* 25.5px / 15.805px ≈ 1.61345 */
}
.blogCardContainer {
  display: flex;
  overflow: hidden; /* Hide overflow to clip carousel items */
  /* padding: 5rem 0 5rem 6.25rem; */
}

.blogCardList {
  display: flex;
  animation: scrollLeft 40s linear infinite; /* Infinite loop animation */
}
.sources {
  color: #a1a1aa;
  font-family: Inter;
  font-size: 14.18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px; /* 158.678% */
}
.blogCard {
  /* padding: 2rem; */

  width: 500px; /* Fixed width for the card */
  height: 492px; /* Fixed height for the card */
  border: 1px solid rgba(255, 255, 255, 0.05); /* Border with opacity */
  margin-right: 20px; /* Margin between cards */
  flex: 0 0 auto; /* Prevent cards from shrinking */
  color: #333;
  padding: 20px;
  transition: transform 1s ease; /* Smooth transition for sliding effect */
  border-radius: 20px;
  background: rgba(29, 32, 35, 0.2);
  box-shadow: 0.199px 0.796px 0.821px -0.937px rgba(0, 0, 0, 0.18),
    0.604px 2.415px 2.489px -1.875px rgba(0, 0, 0, 0.17),
    1.596px 6.383px 6.579px -2.812px rgba(0, 0, 0, 0.15),
    5px 20px 20.615px -3.75px rgba(0, 0, 0, 0.06),
    0px 0.5px 0px 0px rgba(255, 255, 255, 0.1) inset;
}

.blogCard img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* padding: 1.5rem; */
}

.blogCardText {
  padding: 1.5rem;
  margin-bottom: 10px;
}

.blogCardSource {
  color: #fff;
  font-family: Inter;
  font-size: 14.414px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px; /* 156.098% */
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 600px) {
  .blogCard {
    /* width: calc(100% - 40px);  */
    height: auto; /* Auto height for responsiveness */
    margin-right: 0;
    margin-bottom: 20px; /* Space between cards on smaller screens */
  }
}

@media (max-width: 768px) {
  .blogContainer {
    padding: 3rem 0.5rem; /* Adjust padding for mobile devices */
    margin: 50px auto; /* Reduce margin on smaller screens */
  }
  .blogTitle {
    font-size: 2rem; /* Further reduce font size for smaller screens if needed */
    line-height: 1.3; /* Adjust line-height for smaller screens */
    letter-spacing: -0.015em; /* Adjust letter-spacing for smaller screens */
    text-align: center;
  }
  .btnDiv {
    display: flex;
    justify-content: center;
  }
  .blogButton {
    width: 11rem; /* Keep width for larger screens */
    height: 3rem;
    padding: 0 0rem; /* Keep padding for larger screens */
    border-radius: 3rem; /* Keep border radius for larger screens */
    box-shadow: 0px 6.103px 0px -4.882px rgba(255, 255, 255, 0.1) inset; /* Keep box shadow for larger screens */
  }

  .blogCard img {
    /* width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover; */
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    max-height: 150px; /* Decrease maximum height for smaller screens */
    border-radius: 20px;
  }
  .blogCard {
    /* width: calc(100% - 40px); */
    height: auto; /* Auto height for responsiveness */
    margin-right: 0;
    margin-bottom: 20px; /* Space between cards on smaller screens */
  }
  .blogCard img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* padding: 1.5rem; */
  }
}
